<template>
<div>
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="300px">
            <v-card>
                <s-toolbar label="Motivo"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text label="Motivo de Desaprobación" autofocus v-model="CttObservationDissaproved" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="disapproved()">Anular</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">
            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="closeCircuit()" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row style="">
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-card outlined width="100%" style="margin-top:10px">
                                <v-data-table disable-sort dense item-key="AccID" :items="circuits" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center'},
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                            </v-card>
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <order-cotization-edit @onClose="dialog = false; refresh()" :value="CotizationEdit.CttID"></order-cotization-edit>
        </v-dialog>
    </div>

    <div v-if="dialogComparativo">
        <v-dialog class="elevation-0" v-model="dialogComparativo" width="100%" persistent transition="dialog-bottom-transition">
            <ord-cuardro-comparativo @onClose="dialogComparativo = false;" :value="CotizationEdit.CttID"></ord-cuardro-comparativo>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogLoad" v-model="dialogLoad" persistent transition="dialog-bottom-transition">
            <order-edit @onClose="dialogLoad = false; refresh()" @clearItems='itemsSAP=[];' @onCloseClear="dialogLoad = false;itemsSAP=[];" :typeOrder="typeOrder" :order="order" :value="item.OrdID"></order-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogLegal" v-model="dialogLegal" persistent transition="dialog-bottom-transition">
            <lgl-requirement-edit @close="dialogLegal = false; refresh()" :order="order" :value="item.ReqIDLegal"></lgl-requirement-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogReq" v-model="dialogReq" persistent transition="dialog-bottom-transition">
            <lgs-requirement-edit @close="dialogReq = false;" :value="item.ReqID"></lgs-requirement-edit>
        </v-dialog>
    </div>

    <!-- NIVELES DE APROBACION DE OC-->
    <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="DocEntry" :TypeObject="3" />
    </v-dialog>

    <v-row>
        <v-col md="12" lg="12" sm="12" class="pb-1">
            <s-crud class="elevation-0 table table-hover table-condensed" :add="item.CttStatus == 1 && item.OrdID == null" @add="previewSap(1)" no-full height="400" sortable searchInput add-no-function :config="this.config" ref="crud" :filter="filter" title="Cotizaciones" @rowSelected="selectRows($event)" :view="this.item.CttID > 0" @edit="view()" :remove="this.item.CttID > 0 && item.CttStatus == 4">
                <template v-slot:options>

                    <v-btn title="Solic. Elaboración de Contrato" elevation="0" v-if="item.CttStatus == 1 && item.ReqIDLegal == null" style="text-transform:capitalize" small icon @click="previewSap(2)">

                        <v-icon style="font-size: 16px">fa-solid fa-file-contract</v-icon>
                    </v-btn>

                    <v-btn title="Cuadro Comparativo" elevation="0" v-if="item.CttID > 0 && item.CttStatus == 1" style="text-transform:capitalize" small icon @click="previewCotizar()">

                        <v-icon style="font-size: 16px">fa-solid fa-table</v-icon>
                    </v-btn>

                    <v-btn title="Enviar a Aprobación" elevation="0" v-if="(item.CttID > 0 && item.CttStatus == 4  && item.SecStatus == 3) && item.CttExistsCotization > 0 && $fun.isAdmin()" style="text-transform:capitalize" small icon @click="sendCircuit()">

                        <v-icon style="font-size: 16px">fa-solid fa-paper-plane</v-icon>
                    </v-btn>
                </template>

                <template v-slot:filter>
                    <v-container>
                        <v-row justify="center">
                            <v-col lg="3" md="3" cols="6" class="pt-0 pb-0" v-if="$fun.isAdmin()">
                                <c-select-area full clearable noDefault v-model="filter.AreID" label="Área" :typeArea="3" />
                            </v-col>
                            <v-col lg="3" md="3" cols="6" class="pt-0 pb-0">
                                <s-date clearable label="Fecha Inicio" v-model="filter.BeginDate" />
                            </v-col>
                            <v-col lg="3" md="3" cols="6" class="pt-0 pb-0">
                                <s-date clearable label="Fecha Fin" nullable v-model="filter.EndDate" />
                            </v-col>
                            <v-col lg="3" md="3" cols="6" class="pt-0">
                                <s-select-definition clearable nullable label="Estados" :def="1169" v-model="filter.CttStatus" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>

                <template v-slot:CttStatusName="{ row }">
                    <v-chip x-small :color="row.CttStatusColor">
                        {{ row.CttStatusName }}<span>
                            <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="dialogApproval= true"><i class="fas fa-eye"></i></v-btn>
                        </span>

                    </v-chip>

                </template>
                <template v-slot:CttReajustePrice="{ row }">

                    <v-chip x-small color="error" v-if="row.CttReajustePrice == true && row.CttStatus == 1">
                        Reaj. Precio
                    </v-chip>
                    <v-chip x-small color="" v-else>
                        No
                    </v-chip>

                </template>

                <template v-slot:OrdDocumentNumber="{ row }">
                    <a style="text-decoration:underline; color:black" @click="showOrd(row, 1)">{{ row.OrdDocumentNumber }}</a>
                </template>
                <template v-slot:ReqDocumentNumberLegal="{ row }">
                    <a style="text-decoration:underline;" @click="showOrd(row, 2)">{{ row.ReqDocumentNumberLegal }}</a>
                </template>
                <template v-slot:ReqDocumentNumber="{ row }">
                    <a style="text-decoration:underline;color:black" @click="showOrd(row, 3)">{{ row.ReqDocumentNumber }}</a>
                </template>

                <template v-slot:xA="{ row }">
                    <v-chip x-small v-if="(row.CttID > 0 && row.CttStatus == 4  && row.SecStatus == 1) && row.CttExistsCotization > 0 && $fun.isAdmin()">Pendiente Ap.</v-chip>
                </template>

                
            </s-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/CotizationService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";

//Components
import OrderCotizationEdit from "@/views/Logistics/OrderCotization/OrderCotizationEdit";
import OrdCuardroComparativo from '@/views/Logistics/OrderCotization/OrdCuardroComparativo.vue';
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import LglRequirementEdit from "@/views/Legal/Requirement/LglRequirementEdit.vue";
import LgsRequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit.vue";
//import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";

export default {
    components: {
        OrderCotizationEdit,
        OrdCuardroComparativo,
        ApprovalLevelsDocument,
        OrderEdit,
        LglRequirementEdit,
        LgsRequirementEdit
        /* OrderPurchaseEdit */
    },

    data: () => ({
        processing: false,
        messageProcessing: "",
        filter: {
            BeginDate: null,
            EndDate: null,
            CttStatus: null,
            AreID: null
        },
        config: {
            model: {
                CttID: "ID",
                CttDate: "date",
                CttDateEnd: "datetime",
                CttStatusName: "string",
                SecUpdate: "datetime",
                OrdDocumentNumber: "string",
                CttReajustePrice: "",
                ReqDocumentNumberLegal: "",
                ReqDocumentNumber: "",
                xA:""
            },
            service: _sCotization,
            headers: [{
                    text: "ID",
                    value: "CttID",
                    sortable: false
                },
                {
                    text: "Nro. Cot.",
                    value: "CttDocumentNumber",
                    width: 120,
                    sortable: false
                },
                {
                    text: "Nro. Requerimiento",
                    value: "ReqDocumentNumber",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Nro. Doc.Orden",
                    value: "OrdDocumentNumber",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Nro. Solic. Contrato",
                    value: "ReqDocumentNumberLegal",
                    width: 20,
                    sortable: false
                },
                {
                    text: "Fecha",
                    value: "CttDate",
                    sortable: false
                },
                {
                    text: "Solicitante Req.",
                    value: "UsrNameDisparadorInitial",
                    sortable: false,
                    width: 150,
                },
                {
                    text: "Registrado Por",
                    value: "NtpFullName",
                    sortable: false,
                    width: 150,
                },
                {
                    text: "P. días",
                    value: "CttDayExpiration",
                    sortable: false
                },
                {
                    text: "Fec. Fin",
                    value: "CttDateEnd",
                    sortable: false,
                    width: 100,
                },
                {
                    text: "Fecha Act.",
                    value: "SecUpdate",
                    sortable: false
                },
                {
                    text: "T. Transcurrido Proceso",
                    value: "CttTimeElapsed",
                    sortable: false,
                    align: "center"
                },

                {
                    text: "Estado",
                    value: "CttStatusName",
                    sortable: false,
                    align: "left",
                    width: 120
                },
                {
                    text: "Reaj. Precio",
                    value: "CttReajustePrice",
                    sortable: false,
                    align: "center",
                    width: 50
                },
                {
                    text: "Por A",
                    value: "xA",
                    sortable: false,
                    align: "center",
                    width: 50
                },
            ],
        },
        item: {},
        dialog: false,
        dialogComparativo: false,
        dialogOrder: false,
        CotizationEdit: {},

        circuits: [],
        dialogCircuit: false,
        selectedCircuit: [],
        filterLevels: {},
        configLevels: {
            model: {
                AccID: "int",
                AlvID: "ID",
                SecStatus: "boleam",
                PstID: "int",
                PstName: "string",
            },
            service: _sApprovalLevel,
            headers: [{
                    text: "Cargo",
                    value: "PstName"
                },
                {
                    text: "Nivel",
                    value: "TypeLevelText"
                },
            ],
        },

        processing: false,
        messageProcessing: "",
        DocEntry: null,
        dialogApproval: false,

        dialogLoad: false,
        dialogLegal: false,
        dialogReq: false,
        itemsSAP: [],
        order: {
            detail: []
        },
        typeOrder: 1,
        dialogDissaproved:false,
        CttObservationDissaproved: null
    }),
    created() {
        // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
        if (!this.$fun.isAdmin()) this.filter.AreID = this.$fun.getUserInfo().CtrContract.AreID;
    },
    methods: {

        refresh() {
            this.$refs.crud.refresh()
        },

        closeCircuit() {
            this.dialogCircuit = false
        },

        selectRows(items) {
            if (items.length > 0) {
                this.item = items[0];
                this.DocEntry = this.item.CttID
                this.typeOrder = this.item.TypeOrder
            } else {
                this.item = {}
            }

        },

        view() {
            console.log(this.item)
            this.CotizationEdit.CttID = this.item.CttID
            this.dialog = true;
        },

        showOrd(item, op) {
            this.item = item
            if (op == 1) {
                if (this.item.OrdID !== null) {
                    this.dialogLoad = true
                }
            }
            if (op == 2) {
                if (this.item.ReqIDLegal !== null) {
                    this.dialogLegal = true
                }
            }

            if (op == 3) {
                if (this.item.ReqID !== null) {
                    this.dialogReq = true
                }
            }

        },

        previewCotizar() {
            /* this.$fun.alert("Generación de Cuadro Compartivo, se procederá a finalizar el proceso. \nSeguro de continuar ?", "question", false).then(val => {
                if(val.value){ */
            this.CotizationEdit.CttID = this.item.CttID
            this.dialogComparativo = true
            /*  }
            }) */
        },
        sendCircuit() {
            console.log("sendCircuit", this.item)

            if (this.item.UsrDisparadorInitial == null) {
                this.$fun.alert("[IMP], No existe disparador de origen, Comuniquse con el Área de TI", "error")
                return
            }

            this.item.UsrCreateID = this.$fun.getUserID()
            this.$fun.alert("Generar Cuadro Comparativo ?", "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.sendCircuit(this.item, this.$fun.getUserID(), 0).then((resp) => {
                            if (resp.data.length > 1) {
                                this.circuits = resp.data;
                                this.dialogCircuit = true;
                                this.processing = false
                            } else {
                                this.$fun.alert("Guardado Correctamente", "success");
                                this.processing = false
                                this.$refs.crud.refresh()
                                this.$emit("close");
                            }

                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {

            this.item.UsrCreateID = this.$fun.getUserID()

            this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sCotization.sendCircuit(this.item, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
                            this.processing = false
                            this.$fun.alert("Guardado Correctamente", "success");
                            this.closeCircuit()
                            this.$refs.crud.refresh()
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });
        },

        //********************************** FIN CIRCUITOS DE APROBACION************************************************* */
        previewSap(op) {

            if (this.item.CtpIDganador == null) {
                this.$fun.alert("[IMP][Complete el proceso.]", "error")
                return
            }

            _sCotization.itemsproviderbyID(this.item.CtpIDganador, this.item.CttID, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {

                    if (resp.data.SupID == null) {
                        this.$fun.alert("[Participante " + resp.data.CtpRuc + "], no se encuentra registrado, comunique con el área encargada para el registro correspondiente.", "error")
                        return
                    }
                    //Datos generales
                    this.order.TypePercentaje = resp.data.TypePercentaje
                    this.order.OrdTaxPercentage = resp.data.OrdTaxPercentage
                    this.order.TypeCurrency = resp.data.TypeCurrency
                    this.order.TypeCredito = resp.data.TypeCredito
                    this.order.TypeFormaPago = resp.data.TypeFormaPago
                    this.order.OrdDeliveryTime = resp.data.PrvDeliveryTime
                    this.order.OrdTimeGarantia = resp.data.PrvTimeGarantia
                    this.order.OrdDirectCosts = resp.data.OrdDirectCosts
                    this.order.OrdGeneralgto = resp.data.OrdGeneralgto
                    this.order.OrdUtilities = resp.data.OrdUtilities
                    this.order.OrdPolicy = resp.data.OrdPolicy
                    this.order.CttID = resp.data.CttID
                    this.order.ReqID = this.item.ReqID
                    this.order.AgcMin = resp.data.AgcMin
                    this.order.AgcMax = resp.data.AgcMax

                    //Datos Proveedor
                    let sup = {
                        SupPK: resp.data.SupPK,
                        TypePerson: resp.data.TypePerson,
                        SupID: resp.data.SupID,
                        SupName: resp.data.SupName,
                        ConName: resp.data.ConName,
                        ConTelephone: resp.data.ConTelephone,
                        ConEmail: resp.data.ConEmail
                    };
                    this.order.sup = sup

                    //Detalle
                    this.itemsSAP = resp.data.details
                    this.order.detail = this.itemsSAP;

                    for (let i = 0; i < this.order.detail.length; i++) {

                        this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                        this.order.detail[i].OddDescription = this.order.detail[i].CtdDescription;
                        this.order.detail[i].OddObservation = this.order.detail[i].CtdObservation;
                        this.order.detail[i].OddQuantity = this.order.detail[i].CtdQuantity;
                        this.order.detail[i].DerQuantity = this.order.detail[i].CtdQuantity;
                        this.order.detail[i].Line = i + 1;
                    }

                    if (this.itemsSAP.length > 0) {
                        //this.dialogLoad = true
                        if (op == 1) {
                            this.item.OrdID = null
                            console.log("resp.data",resp.data)
                            this.dialogLoad = true
                        }
                        if (op == 2) {
                            //400 >= 9000
                            if (resp.data.OrdTotal >= resp.data.AgcMin && resp.data.OrdTotal <= resp.data.AgcMax) {
                                
                                this.item.ReqIDLegal = null
                                this.order.OrdSubTotal = resp.data.OrdSubTotal
                                this.order.OrdTaxAmount = resp.data.OrdTaxAmount
                                this.order.OrdTotal = resp.data.OrdTotal
                                this.dialogLegal = true
                            } else {
                                this.$fun.alert("[IMP]. No cumple con los criterios establecidos para la generación de contrato." + " Min [" + resp.data.AgcMin + "]", "error")
                            }
                        }
                    }
                }
            })

        },

    },

};
</script>
